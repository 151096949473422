import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import React, { CSSProperties, FunctionComponent } from "react"
import Img, { FluidObject } from "gatsby-image"

interface HeroProps {
  logo?: string
  logoAlt?: string
  heading: string
  lead: string
  fluidImage: FluidObject
}

const Hero: FunctionComponent<HeroProps> = props => {
  return (
    <div style={commonStyles}>
      <Container>
        <Row>
          <Col>
            {props.logo && props.logoAlt ? (
              <img
                src={props.logo}
                alt={props.logoAlt}
                width={120}
                height={120}
                style={paddingStyles}
              />
            ) : null}

            <h1 className="display-4">{props.heading}</h1>
            <p className="lead" style={paddingStyles}>
              {props.lead}
            </p>
            <div>{props.children}</div>
          </Col>
        </Row>
      </Container>
      <Img
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
          zIndex: -1,
        }}
        fluid={props.fluidImage}
      />
    </div>
  )
}

const commonStyles: CSSProperties = {
  minHeight: "568px",
  display: "flex",
  alignItems: "center",
  color: "white",
  textAlign: "center",
  backgroundSize: "cover",
  backgroundPosition: "center",
  paddingTop: "2rem",
  paddingBottom: "2rem",
  overflow: "hidden",
  position: "relative",
}

const paddingStyles: CSSProperties = {
  marginBottom: "2rem",
}

export default Hero
