import React, { FunctionComponent } from "react"
import Hero from "../components/hero"
import { graphql, Link, PageProps } from "gatsby"
import Layout from "../layouts/layout"
import { fluidWithIdentifier } from "../helpers/image-helpers"
import { MetadataProps } from "../types/types"
import Button from "react-bootstrap/Button"

const FileNotFoundPage: FunctionComponent<PageProps<MetadataProps>> = ({
  data,
}) => {
  return (
    <Layout>
      <Hero
        fluidImage={fluidWithIdentifier("hero", data)}
        heading="Page Not Found"
        lead="Successful people keep moving. They make mistakes, but they don’t quit."
      >
        <Link to="/">
          <Button>Runloop Home</Button>
        </Link>
      </Hero>
    </Layout>
  )
}

export default FileNotFoundPage

export const query = graphql`
  fragment fluidBackground on File {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
  query FileNotFoundQuery {
    site {
      siteMetadata {
        title
        emails {
          privacy
          support
        }
        urls {
          support
          seconds {
            appStore
            googlePlay
          }
          heavyset {
            appStore
          }
        }
      }
    }
    hero: file(relativePath: { eq: "images/404.jpg" }) {
      ...fluidBackground
    }
  }
`
